@import '../../style/application/settings';
@import '../../style/foundation/foundation.settings';

.app {
  background-color: white;
  height:           100%;

  &-overlay {
    display: block;
    bottom:  0;
    right:   0;
    opacity: 1;
    z-index: 200;
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
  }
}

.background {
  position:            absolute;
  top:                 0;
  left:                0;
  width:               100%;
  height:              100%;
  background-size:     cover;
  background-position: top center;
  // Note: `background-image` is set in the React component.
}

.c-question-plumbob__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 50;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.c-question-plumbob {
  display: none;

  @include breakpoint(large) {
    display: block;
    position: absolute;
    top: 16vh;
    z-index: 100;
    width: 12rem;
    height: 12rem;
  }

  img {
    height: 100%;
    width: 100%;
  }
}
