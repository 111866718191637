@import '../../../style/application/settings';
@import '../../../style/foundation/foundation.settings';

$contentWidth: 50vw;

.screen-intro {
  height: 100%;
  z-index: 100;
  overflow: hidden;

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include breakpoint(medium only) {
    max-height: 100vh;
    overflow: hidden;
  }
}

.intro-contentWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include breakpoint(medium) {
    flex-grow: 0;
    height: 80vh;
    justify-content: space-between;
  }

  @include breakpoint(xlarge) {
    justify-content: flex-start;
  }
}

.intro-slider,
.intro-content {
  height: 100%;
}

.intro-slider {
  display: none;

  @include breakpoint(medium) {
    display: block;
    z-index: 80;
    flex-basis: 18vw;
  }

  @include breakpoint(xlarge) {
    flex-basis: 30vw;
  }

  &--left {
    display: none;

    @include breakpoint(xlarge) {
      display: block;
    }
  }
}

.intro-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(medium) {
    flex-basis: $contentWidth;
  }

  @include breakpoint(xlarge) {
    flex-grow: 1;
  }

  // Nasty iOS fix
  @include breakpoint(small down) {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.c-title {
  color: $color-text-body;
  text-align: center;
  width: 100%;
  z-index: 100;
  font-size: 10vw;

  @include breakpoint(medium) {
    font-size: 8vw
  }

  @include breakpoint(large) {
    padding: 0 4rem;
    font-size: 5.6vw;
  }
  
  @include breakpoint(small down) {
    font-size: 8vw;
    padding: 0 2rem;
  }
}

.c-button {
  width: 100%;
  z-index: 1000;
  white-space: nowrap;

  @include breakpoint(xlarge) {
    width: 60%;
    margin: 0 auto;
  }

  button {
    width: 80%;
    font-size: 3rem;
    line-height: 1;
    padding: 2.4rem 2rem;
    display: block;
    margin: 0 auto;

    @include breakpoint(medium) {
      width: auto;
      font-size: 4rem;
    }

    @include breakpoint(large) {
      font-size: 4.5rem;
    }
    
    @include breakpoint(small down) {
      font-size: 2.6rem;
      padding: 1.8rem 1.6rem;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
}

.logo {
  width: 100%;
  z-index: 100;
  max-width: 80vw;
  margin: 2rem auto 0;

  @include breakpoint(medium) {
    width: 75%;
  }

  @include breakpoint(xlarge) {
    max-width: 54rem;
  }

  @include breakpoint(small down) {
    max-width: 70vw;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  color: white;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint(medium down) {
    position: relative;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include breakpoint(xlarge) {
    justify-content: flex-start;
  }

  &__textWrapper {
    display: flex;
    justify-content: center;
    @include breakpoint(medium) {
      flex-basis: $contentWidth;
    }

    @include breakpoint(xlarge) {
      flex-grow: 1;
    }
  }

  &__text {
    max-width: 60rem;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1rem;
    @include breakpoint(small down) {
      font-size: 12px;
    }
  }

  &__edge {
    display: none;

    @include breakpoint(xlarge) {
      display: none;
    }
  }

  &__logos {
    justify-content: space-around;
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(xlarge) {
      position: absolute;
      right: 7rem;
      bottom: 4rem;
      z-index: 100;
    }
  }

  &__logo {
    margin: 1rem 1rem 2rem;
    height: 18vw;
    width: 18vw;
    
    @include breakpoint(small down) {
      height: 16vw;
      width: 16vw;
    }

    @include breakpoint(medium) {
      height: 10rem;
      width: 10rem;
    }

    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-right: auto;
    }
  }

  p {
    text-align: center;
    padding-bottom: 6%;
  }

  @include breakpoint(xlarge) {
    font-size: 16px;

    p {
      max-width: 40vw;
    }
  }
}

.retailer-wrapper {
  max-width: 100%;
  padding: 2rem 6rem;
  background-color: #FFF;
  z-index: 100;
  box-shadow: 3px 5px 7px 0px hsla(0, 0%, 0%, 0.25);
    
  @include breakpoint(medium down) {
    padding: 2rem;
    text-align: center;
  }

  @media(max-width: 360px) {
    padding: 1rem 2rem;
  }
}

.retailer-logo {
  max-width: 100%;
  max-height: 7rem;
  height: auto;
  
  @media(max-width: 360px) {
    max-height: 4rem;
  }
}
