@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  20% {
    opacity: 1;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes zoomFocus {
  0% {
    transform: scale3d(1, 1, 1);
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes grow {
  0% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}


@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }
  60% {
    transform: translate3d(2%, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInRightNoFade {
  to {
    animation-timing-function: ease-out;
  }
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  90% {
    transform: translate3d(2%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes packAnimate {
  to {
    animation-timing-function: ease-out;
  }
  0% {
    transform: translate3d(200%, 0, 0);
    z-index: 3;
  }
  20% {
    transform: translate3d(310%, 0, 0);
  }
  50% {
    transform: translate3d(-800%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
    order: 0;
    z-index: 0;
  }
}

@keyframes loadingDot {
      0% { transform: scale(1) translateY(0); }
     50% { transform: scale(1.5) translateY(-0.06em); }
    100% { transform: scale(1) translateY(0); }
}

@keyframes constantRotate {
    from { transform: rotate(0deg); }
    20% { transform: rotate(720deg); }
    to { transform: rotate(720deg); }
}
