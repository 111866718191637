@import './settings';
@import '../foundation/foundation.settings';

[class*="intro-slider"] {
  .slick-slider {
    height: 100%;
  }
  .slick-current {
    z-index: 0;
  }
  .slick-slide {
    position: relative;
    img {
      height: 14vh;
      opacity: 0;
      transition: all 0.9s;
      transform: translateX(4rem);
      transform-origin: bottom 0;

      @include breakpoint(medium) {
        height: 20vh;
      }
    }
  }

  .slick-active {
    img {
      transform: translateX(0);
      transition: all 0.9;
      opacity: 0.5;
    }
  }
  .slick-active + .slick-active {
    img {
      transform: scale(3) translateX(4rem);
      opacity: 0.8;
    }
  }
  .slick-active + .slick-active + .slick-active {
    img {
      transform: scale(4.5);
      opacity: 1;
    }
  }
  .slick-active + .slick-active + .slick-active + .slick-active {
    img {
      transform: scale(6) translateX(4rem);
      opacity: 1;
    }
  }
}

.intro-slider-right {
  .slick-slide {
    img {
      float: right;
    }
  }
  .slick-active + .slick-active {
    img {
      transform: scale(3) translateX(-4rem);
    }
  }
  .slick-active + .slick-active + .slick-active {
    img {
      transform: scale(4.5);
      opacity: 1;
    }
  }
  .slick-active + .slick-active + .slick-active + .slick-active {
    img {
      transform: scale(6) translateX(-4rem);
    }
  }
}
