@import '../../../style/application/settings';
@import '../../../style/foundation/foundation.settings';

.screen-loading {
  position: relative;
  height:   100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-loading {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12rem;
  }
  &__plumbob {
    position: absolute;
    top: 0;
    left: calc(50% - 6rem);
    z-index: 100;
  }
  &__plumbobInner {
    width: 12rem;
    height: 12rem;
  }

  &__text {
    padding-top: 2rem;
    padding-left: 1em; // Account for dots on right but visibly center word
    color: $color-text-body;
    text-align: center;
    width: 100%;
    z-index: 100;
    font-size: 6vw;

    @include breakpoint(medium) {
      font-size: 4vw
    }

    &-one:global,
    &-two:global,
    &-three:global {
      display: inline-block;
      line-height: 0;
      animation: loadingDot 0.9s infinite;
      transform-origin: bottom;
      margin-left: 3px;
    }

    &-two {
      animation-delay: 0.3s;
    }

    &-three {
      animation-delay: 0.6s;
    }
  }
}
