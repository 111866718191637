// Type helpers
// ================

#{override('.u-caps')} {
  text-transform: uppercase;
}

#{override('.u-text-center')} {
  text-align: center;
}

#{override('.u-text-right')} {
  text-align: right;
}
