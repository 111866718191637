@import './settings';
@import '../foundation/foundation.settings';

:root {
  --height: 4rem;

  @include breakpoint(large) {
    --height: 5rem;
  }
}

.c-socialLinks {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  box-shadow: 3px 5px 7px 0px hsla(0, 0%, 0%, 0.25);
  z-index: 200;
  max-width: 38vw;

  @include breakpoint(large) {
    max-width: 42vw;
  }

  @include breakpoint(large down) {
    padding: 1.5rem;
    width: 100%;
    max-width: none;
  }

  @include breakpoint(small down) {
    position: relative;
    width: 100vw;
    margin-top: 2rem;
    margin-left: -2vw;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium down) {
      margin-bottom: 1rem;
    }
  }

  &__logo {
    width: auto;
    min-width: var(--height);
    max-width: calc(100% - 5rem);
    height: var(--height);
    align-items: center;
    
    @include breakpoint(large down) {
      margin-right: 1rem;
    }

    @include breakpoint(small down) {
      height: auto;
      max-height: 6rem;
      max-width: calc(100% - 16rem);
      width: auto;
    }
    
    img {
      max-height: 100%;
      width: auto;
      max-width: 50%;
    }
  }

  &__links {
    flex-shrink: 0;
    height: var(--height);

    @include breakpoint(large down) {
      margin-left: 1rem;
    }
  }

  &__socialImage {
    width: var(--height);
    height: var(--height);
    margin-left: 1rem;
  }

  &__copy {
    color: #1c1c1c;
    display: block;
    flex-basis: 100%;
    margin: 1rem 0 0;
    font-size: 1.4rem;
    text-align: left;

    @include breakpoint(large) {
      font-size: 1.8rem;
      margin: 1.5rem 0 0;
    }

    @media(min-width: 768px) and (max-width: 1199px) {
      text-align: center;
    }
  }
}

.result-packs .c-socialLinks__logo {
  filter: none!important;
}
