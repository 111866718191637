.result-button {
  width: 100%;
  padding: 0 2rem;
  color: #044040;
  font-size: 2.5vw;
  padding: 1.5rem 1rem 1rem;
  box-shadow: 0 8px 20px rgba(0,0,0,0.5);
  line-height: 1.2;

  @include breakpoint(medium down) {
    font-size: 2.2rem;
    padding: 1rem 1rem 0.8rem;
    width: auto;
    margin-bottom: 1rem;
  }
  
  &:hover {
    color: white!important;
  }
  
  @include breakpoint(small down) {
    .app_es & {
      font-size: 2rem
    }
  }
}

.result-button--0 {
  color: #044040;
}
.result-button--1 {
  color: #0574FF;
}
.result-button--2 {
  color: #114E02;
}
