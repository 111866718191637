//  Design Tokens
//  -----------------------------
//
//  Table of Contents:
//
//   1. Color
//   2. Background Color
//   3. Text Color
//   4. Border Color
//   5. Font
//   6. Font Size
//   7. Line Height
//   8. Opacity
//   9. Spacing
//  10. Radius
//  11. Sizing
//  12. Shadow
//  13. Z-index
//  14. Animation

// 1. Color
// --------
// Brand Colors
$brand-navy: #1E2454;
$brand-sky: #56D2EA;
$brand-purple: #4D4689;
$brand-mustard: #D7CA64;
$brand-green: #007B57;

$brand-white: #ffffff;
$brand-black: #000000;
// REMOVE ABOVE ^^^


// SIMS Palette
$sim-primary-light: #12FCFD;
$sim-primary-mid: #0CBFC0;
$sim-primary-mid-dark: #239799;
$sim-primary-dark: #077E7E;
$sim-primary-darkest: #034042;
$sim-secondary: #0601FF;
$sim-white: #ffffff;

// Palette map - useful for looping through to generate modifiers.
$palette: (
  'navy': $brand-navy,
  'sky': $brand-sky,
  'purple': $brand-purple,
  'mustard': $brand-mustard,
  'green': $brand-green,
  'white': $brand-white,
  'black': $brand-black,
);

// 2. Background Color
// -------------------
$color-background: $sim-primary-mid; // Default background color for the whole app.

// 3. Text Color
// -------------
$color-text-body: $sim-white; // Body text color.
$color-text-heading: $sim-white; // Heading text color.
$color-text-link: $sim-white; // Text link color.
$color-text-link-hover: $sim-primary-dark; // Text link hover color.

// 4. Border Color
// ---------------

// 5. Font
// -------

@font-face {
  font-family: 'TheSimsSans';
  src: url('../../assets/fonts/TheSimsSans.woff2') format('woff2'),
       url('../../assets/fonts/TheSimsSans.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TheSimsSans';
  src: url('../../assets/fonts/TheSimsSans-Bold.woff2') format('woff2'),
       url('../../assets/fonts/TheSimsSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


$font-family-heading: 'TheSimsSans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-body: 'TheSimsSans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';


// 6. Font Size
// ------------
// NOTE: Left empty as a FitText plugin might be best here instead of fixed sizes. TBD.

// 7. Line Height
// --------------
$line-height-heading: 1; // H1 - H6 line height.
$line-height-body: 1.4; // Body copy line height.

// 8. Opacity
// ----------
$opacity-9: 0.9; // 40% transparency of an element.

// Opacity map - useful for looping through to generate modifiers.
$opacities: (
  '9': $opacity-9,
);

// 9. Spacing
// ----------
$border-width: 0.1rem; // Global divider & border width.
$spacing-reset: 0;
$spacing-xlarge: 12rem;
$spacing-large: 6.4rem;
$spacing-medium: 4rem;
$spacing-small: 3.2rem;
$spacing-xsmall: 2.4rem;
$spacing-xxsmall: 1.6rem;
$spacing-xxxsmall: 1rem;

// Spacing map - useful for looping through to generate modifiers.
$spaces: (
  'reset': $spacing-reset,
  'xlarge': $spacing-xlarge,
  'large': $spacing-large,
  'medium': $spacing-medium,
  'small': $spacing-small,
  'xsmall': $spacing-xsmall,
  'xxsmall': $spacing-xxsmall,
  'xxxsmall': $spacing-xxxsmall,
);

// 10. Radius
// ----------


// 11. Sizing
// ----------


// 12. Shadow
// ----------
// $shadow-default: 0 0 30px 0 rgba(0, 0, 0, 0.1); // Global box-shadow style.
// $shadow-hover: 0 5px 15px 0 rgba(0, 0, 0, 0.15); // Button / link object hover shadow style.

// 13. Z-index
// -----------
$z-index-deepdive: -99999; // Deep dive.
$z-index-default: 1; // Default.
$z-index-dialog: 6000; // Dialog.
$z-index-docked: 4; // Docked.
$z-index-dropdown: 7000; // Dropdown.
$z-index-modal: 9000; // Modal.
$z-index-overlay: 8000; // Overlay.
$z-index-popup: 5000; // Popup.
$z-index-megamenu: 4000; // Megamenu.
$z-index-reminder: 8500; // Notifications under modals.
$z-index-spinner: 9050; // Spinner.
$z-index-sticky: 100; // Stickied element.
$z-index-cookies: 9500; // Cookie banner.
$z-index-toast: 10000; // Toasts.

// z-index - useful for looping through to generate modifiers.
$zindexes: (
  'deepdive': $z-index-deepdive,
  'default': $z-index-default,
  'dialog': $z-index-dialog,
  'docked': $z-index-docked,
  'dropdown': $z-index-dropdown,
  'modal': $z-index-modal,
  'overlay': $z-index-overlay,
  'popup': $z-index-popup,
  'reminder': $z-index-reminder,
  'spinner': $z-index-spinner,
  'sticky': $z-index-sticky,
  'toast': $z-index-toast,
);

// 14. Animation
// -------------
$transition-timing: 0.25s; // Default transition timing.
$transition-easing: ease-in-out; // Default transition easing.
$transition: $transition-timing $transition-easing; // Default transition setting.
