// Link element styles
// ===================

a {
  color: $brand-white;
  transition: color $transition-timing $transition-easing;

  &:hover,
  &:active,
  &:focus {
    color: $brand-sky;
  }
}
