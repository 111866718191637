@import '../../../style/application/settings';
@import '../../../style/foundation/foundation.settings';

.screen-gathering-result {
  height:     100%;
  text-align: center;
  overflow:   hidden;
}

.results {
  &__img {
    z-index: 0;
  }
  &__vid {
    z-index: 1;
  }
  &__vid,
  &__img {
    position:        absolute;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    object-fit:      cover;
    object-position: 54% 90%;
  }
}

.message {
  position:  absolute;
  top:       20%;
  left:      50%;
  transform: translate(-50%, -50%);
  width:     75%;
}

.c-gathering__titleWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 50;
  font-family: $font-family-heading;
  line-height: 1;
  text-align: center;
  transform: translateX(-50%);
  background-color: $sim-primary-light;
  clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 3% 88%);
  width: 112vw;
  padding: 2rem 4rem;

  @include breakpoint(medium) {
    width: 66vw;
    padding: 8rem;
  }
}

.c-gathering__title {
  color: $sim-primary-darkest;
  display: block;
  font-weight: 700;
  font-size: 3.2rem;

  @include breakpoint(medium) {
    font-size: 4vw;
  }
}

.c-gathering__subtitleWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 50;
  font-family: $font-family-heading;
  line-height: 1;
  text-align: center;
  transform: translateX(-50%);
  background-color: $sim-primary-dark;
  clip-path: polygon(4% 8%, 95% 0%, 100% 100%, 0% 100%);
  width: 112vw;
  padding: 3rem;

  @include breakpoint(medium) {
    width: 66vw;
    padding: 6rem 8rem 4rem;
  }
}

.c-gathering__subtitle {
  color: $sim-white;
  display: block;
  font-weight: 700;
  font-size: 2rem;

  @include breakpoint(medium) {
    font-size: 3vw;
  }
}
