@import '../../../style/application/settings';
@import '../../../style/foundation/foundation.settings';

.screen-question {
  position: relative;
  height:   100%;
  overflow: hidden;
}

.c-question {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 2vh;
  height: 100%;
  padding: 6rem 2rem 2rem;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 2.5vw;
    padding: 4%;
    max-width: 150rem;
  }

  @include breakpoint(xlarge) {
    max-width: 200rem;
  }

  &__textWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 50;
    filter: drop-shadow(3px 3px 3px rgba(0,0,0,.4));
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    font-family: $font-family-heading;
  }

  &__text {
    background: $sim-primary-dark;
    width: 85vw;
    max-width: 40rem;
    height: 0;
    margin-top: 2rem;
    padding-top: 24%;
    text-align: center;
    clip-path: polygon(0% 0%, 100% 4%, 95% 98%, 6% 100%);
    font-weight: 700;
    
    @include breakpoint(medium) {
      width: 60vw;
      max-width: 50rem;
    }
    
    @include breakpoint(large) {
      width: 35vw;
      padding-top: 36%;
      max-width: 64rem;
      margin-top: 30vh;
    }

    &Inner {
      margin: 0;
      position: absolute;
      top: 5%;
      right: 5%;
      bottom: 5%;
      left: 5%;
      color: $brand-white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.4vw;
      line-height: 1.2;
      
      @include breakpoint(small down) {
        font-size: 1.6rem;
      }
    }
  }

}

.c-answer {
  position:relative;
  &__figure {
    height:100%;
    filter: drop-shadow(0 5px 15px rgba(0,0,0,.7));
    div {  // For <AnimationSlideOut> component
      height: 100%;
    }
  }
  &__imageHolder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &--a {
      background-color: $sim-primary-mid;
      clip-path: polygon(0 0%, 100% 1%, 95% 98%, 2% 100%);

      img,
      video {
        clip-path: polygon(0 0%, 100% 1%, 95% 98%, 2% 100%);
      }
    }
    &--b {
      background-color: $sim-primary-light;
      clip-path: polygon(4% 5%, 100% 2%, 100% 100%, 0% 98%);

      img,
      video {
        clip-path: polygon(4% 5%, 100% 2%, 100% 100%, 0% 98%);
      }
    }
  }

  &__image,
  &__video {
    position: absolute;
    left: 2.5rem;
    top: 2.5rem;
    height: calc(100% - 5rem);
    width: calc(100% - 5rem);
    object-fit: cover;
    object-position: center center;
    
    @include breakpoint(large) {
      left: 3.4rem;
      top: 3.4rem;
      height: calc(100% - 6.8rem);
      width: calc(100% - 6.8rem);
    }
  }
  
  &__video {
    z-index: 10;
  }

  &__footer {
    position: absolute;
    left:0;
    bottom: 12%;
    width:100%;
    z-index: 100;
    text-align:center;
    display: block;
  }

  &__textWrapper {
    z-index: 50;
    filter: drop-shadow(3px 3px 3px rgba(0,0,0,.4));
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    font-family: $font-family-heading;
  }

  &__button {
    background-color: $sim-white;
    cursor: pointer;
    color: $sim-primary-mid-dark;
    width: 64vw;
    max-width: 35rem;
    padding: 1.4rem 1rem 1rem;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    font-size: 1.6rem;
    transition: color $transition,
                background-color $transition,
                transform $transition;
                font-size: 1.8rem;

    @include breakpoint(medium) {
      width: 45vw;
      max-width: 100%;
    }
    @include breakpoint(large) {
      max-width: 34vw;
      font-size: 3rem;
      padding: 2rem 3rem 1.5rem;
      width: auto;
    }

    &:hover,
    &:focus,
    &:active {
      transform: scale(1.1);
      background-color: $sim-secondary;
      color: $sim-white;
      outline: none;
    }
  }
}

.question-corner-top {
  display: none;

  @include breakpoint(large) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;

    &-thing {
      width: 0;
      height: 0;
      border-top: 20rem solid $sim-primary-light;
      border-right: 15rem solid transparent;
    }
  }
}

.question-corner-bottom {
  z-index: 10000;
  position: absolute;
  bottom: 0;
  right: 0;

  &-thing {
    background-color: $sim-primary-darkest;
    color: white;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem 3rem 0.5rem 5rem;
    clip-path: polygon(15% 20%, 100% 0%, 100% 100%, 0% 100%);

    @include breakpoint(large) {
      font-size: 3vw;
      padding: 4rem 4rem 2rem 8rem;
    }
  }
}

@-moz-document url-prefix() {
  .c-answer__video {
    display: none;
  }
  .c-answer__figure > div {
    will-change: transform;
  }
}
