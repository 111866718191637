// Type element styles
// ===================

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-heading;
  color: $color-text-heading;
  line-height: 1;
  text-transform: uppercase;
  font-size: inherit;
}

p {
  margin: $spacing-reset;
  line-height: $line-height-body;
}
