// Object Fit
// ==========

#{override('.u-objectFit--cover')} {
  display: block;
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
    height: 100%;
  }
}

