@import './settings';
@import '../foundation/foundation.settings';

.result-screen {
  display: flex;
  min-height: 100vh;
}

.initial-0.initial-0 {
  order: 0;
  z-index: 0;
}

.result-screen-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
  transition: opacity 0.5s ease;

  @include breakpoint(small down) {
    height: 110vh;

    @media(min-height: 750px) {
      height: 100vh;
    }
  }
  @media(max-width: 360px) {
    height: 118vh;
  }
}

.result-triangleContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;

  @include breakpoint(small down) {
    height: 110vh;
    
    @media(min-height: 750px) {
      height: 100vh;
    }
  }
  @media(max-width: 360px) {
    height: 118vh;
  }
}

.result-triangle {
  position: absolute;
  left: 60%;
  transform: translateX(-50%);
  top: -24vh;
  max-width: 100vw;

  svg {
    height: 180vh;
    width: auto;
  }

  path {
    transition: fill 0.5s ease;
  }

  @include breakpoint(medium down) {
    transform: translateX(0);
    left: -32rem;
    top: -14rem;

    svg {
      height: 140vh;
    }
  }
}

.result-packs {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  z-index: 10;

  // Game pack image
  img {
    filter: drop-shadow(0px 5px 30px rgba(0,0,0,0.3));
  }

  @include breakpoint(medium down) {
    position: absolute;
    left: 2vw;
    width: 64vw;
    padding-top: 10rem;
  }

  @media(max-width: 400px) {
    padding-top: 8rem;
  }
}

.result-pack-slider {
  z-index: 10;
}

.result-content {
  flex-basis: 60%;
  //width: 0;
  min-width: 0;

  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.result-contentInner {
  text-align: center;
  max-width: 35vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14vh;
  height: 100%;

  @include breakpoint(medium down) {
    height: 100%;
    top: 0;
    width: 100%;
    max-width: none;
  }
}

.result-contentSlide {
  height: 100%;
  display: inline-flex !important;
  flex-direction: column;
  justify-content: center;
}

.result-character {
  position: absolute;
  right: 0;
  z-index: 8;
  transition: transform 0.5s $transition;
  transform: translateX(100%);

  @include breakpoint(large) {
    top: 0;
  }

  img {
    transform: scale(1.6);
    transform-origin: 25% 0%;

    @include breakpoint(large) {
      width: 28vw;
      top: 0;
      bottom: auto;
      transform: scale(1.6);
      transform-origin: 25% 0%;
    }
  }

  @include breakpoint(medium down) {
    height: 72vh;
    width: 45vw;
    bottom: -6%;

    picture {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 72vh;
      width: 45vw;
      transform: scale(1.8);
      transform-origin: 20% 0;
    }
  }
}

.result-copyContainer {
  @include breakpoint(medium down) {
    position: absolute;
    top: 6vh;
    left: 50%;
    transform: translateX(-50%);
    width: 84vw;
  }
}

.result-buttons-container {
  @include breakpoint(medium down) {
    width: 100%;
    margin-top: 2rem;

    button {
      max-width: 42vw;
      width: 100%;
    }
  }
}

.result-button {
  @include breakpoint(medium down) {
    width: 80%;
    max-width: 22.5rem
  }
}

.result-header {
  margin-bottom: 2vh;
  font-size: 3.2vw;
  opacity: 0;

  @include breakpoint(large down) {
    font-size: 4.2rem;
  }

  @include breakpoint(medium down) {
    font-size: 3vw;
  }

  @include breakpoint(small down) {
    font-size: 2rem;
  }
}

.result-copyWrapper {
  opacity: 0;
}

.result-copy {
  text-transform: none;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 2vh;
  font-size: 3.5vw;

  @include breakpoint(medium) {
    font-size: 2.4vw;
  }

  @include breakpoint(large) {
    font-size: 1.8vw;
  }

  @include breakpoint(small down) {
    font-size: 3.4vw;
  }
}

.result-disclaimer {
  line-height: 1.3;
  max-width: 60%;
  margin: 0 auto;

  @include breakpoint(medium down) {
    max-width: 80%;
  }
}

.result-buttonContainer {
  margin: 1vh auto;
  text-align: center;

  @include breakpoint(large) {
    margin: 5vh auto;
    max-width: 23vw;
    opacity: 0;
  }
}

.result-startOverContainer {
  width: auto;

  @include breakpoint(large) {
    opacity: 0;
  }
}

.result-startOver {
  background-color: transparent;
  color: $sim-white;
  text-transform: none;
  font-size: 2.2vw;
  line-height: 1;

  @include breakpoint(medium down) {
    font-size: 1.6rem;
    display: block;
    margin: 0 auto;
  }

  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    color: $sim-white;

    .result-startOverIcon {
      transform: rotate(360deg);
    }
  }

  div {
    margin: 0;
  }
}

.result-startOverInner {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.result-startOverIcon {
  margin: 1.6rem;
  height: 4rem;
  width: 4rem;
  transform-origin: center;

  @include breakpoint(medium down) {
    margin: 1rem;
    height: 2rem;
    width: 2rem;
  }

  @include breakpoint(small down) {
    .app_es & {
      display: none;
    }
  }
}

.result-rating {
  position: absolute;
  z-index: 10;
  right: 4rem;
  bottom: 4rem;
  max-width: 10rem;

  @include breakpoint(medium down) {
    max-width: 6rem;
    right: 2rem;
    bottom: 2rem;
  }
}

.result-rating--bottomPadding {
  @include breakpoint(medium down) {
    bottom: 13rem;
  }
  @include breakpoint(small down) {
    bottom: 9rem;
    
    @media(min-height: 750px) {
      bottom: 11rem;
    }
  }
  @media(max-width: 360px) {
    bottom: -3rem;
  }
  @media (orientation: landscape) and (max-width: 1024px) {
    bottom: 15rem;
  }
}

.result-slick-dots {
  transform: translateY(-2rem);
  opacity: 0;
  transition: opacity 0.5s $transition;

  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}

.result-slick-dot {
  background-color: transparent;
  border: 4px solid white;
  border-radius: 50%;
  margin: 2rem;
  display: inline-block;
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.25s ease, background 0.25s ease;
  transform: scale(1);
  
  @include breakpoint(medium down) {
    border: 3px solid white;
    padding: 0.9rem;
    margin: 0 1rem;
  }

  &:active,
  &:hover {
    transform: scale(1.1);
  }

  &.is-active {
    background-color: white;
    transform: scale(1.1);
  }
}

// Current active slide - reveal content with animations
.slick-current {
  z-index: 100;

  .result-header {
    animation: bounceIn 0.8s 0.75s forwards, zoomFocus 0.6s 3.5s forwards;
  }

  .result-copyWrapper {
    animation: slideInLeft 0.6s 1.5s forwards;
  }

  .result-character {
    animation: slideInRightNoFade 0.6s 0.5s forwards;
  }

  .result-character-inner {
    animation: grow 30s forwards;
    transform-origin: 100% 0;
  }

  .result-buttonContainer {
    animation: bounceIn 0.8s 2s forwards;
  }

  .result-startOverContainer {
    animation: bounceIn 0.8s 3s forwards;
  }

  .result-startOverIcon {
    animation: constantRotate 4s infinite forwards;
  }

  .result-slick-dots {
    opacity: 1;
  }
}

// Pack animation
.pack-slide.is-animating {
  animation-name: packAnimate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition: transform 2s ease;
}
