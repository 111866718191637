@import './settings';
@import '../foundation/foundation.settings';

.result-pack-slider {
  height: 38vh;
  width: 30%;
  margin-left: 4vw;
  display: flex;
  
  @include breakpoint(medium) {
    height: 44vh;
  }

  @include breakpoint(large) {
    height: 100%;
  }

  .pack-slide {
    width: 33.3%;
    order: 1;
    z-index: 1;

    @include breakpoint(large) {
      padding-top: 50%;
    }

    img {
      position: absolute;
      width: 40vw;
      max-width: none;

      @include breakpoint(medium only) {
        height: 100%;
      }
    }

    @include breakpoint(large) {
      img {
        width: 26vw;
      }
    }

    &.is-active {
      order: 2;
      z-index: 2;
    }

    &.is-previous {
      order: 0;
      z-index: 0;
    }

    &.is-animating.is-animating {
      z-index: 3;
    }
  }
}


[class*="result-content"] {
  .slick-slider {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .slick-track,
  .slick-list {
    height: 100%;
  }

  .slick-slide {
    overflow: hidden;
    float: left;
    position: relative;
    z-index: 1;
    height: 100%;
    opacity: 0;
    transition: opacity 0.25s ease;

    > div {
      height: 100%;
      width: 100%;
    }

    &.slick-current {
      opacity: 1;
    }
  }

  .slick-current [class*="result-character"] {
    opacity: 1;
  }

  @include breakpoint(small down) {
    .slick-slider {
      height: 110%;
      
      @media(min-height: 750px) {
        height: 100%;
      }
    }
    .slick-slide {
      height: 100%;
    }

    .result-character {
      bottom: 4%;
    }
  }
  @media(max-width: 360px) {
    .slick-slider {
      height: 118%;
    }
    .slick-slide {
      height: 100%;
    }

    .result-character {
      bottom: -4%;
    }
  }
}
