// Document element styles
// =======================

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
}

.app {
  background-color: $brand-white;
  height:           100%;
}
