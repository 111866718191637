// Display
// =======

#{override('.u-display-block')} {
  display: block;
}

#{override('.u-display-inline')} {
  display: inline;
}

#{override('.u-display-inlineBlock')} {
  display: inline-block;
}

#{override('.u-display-flex')} {
  display: flex;
}
