button, .button {
  background-color: $sim-white;
  color: $sim-primary-mid-dark;
  font-family: $font-family-heading;
  font-size: inherit;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  line-height: inherit;
  text-transform: uppercase;
  text-decoration: none;
  transition: color $transition, background-color $transition;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: visible;

  &:hover,
  &:focus,
  &:active {
    background-color: $sim-secondary;
    color: $sim-white !important; // I know, I know... need to defeat inline colour styles tho...
    outline: none;
  }

  &:disabled {

  }

  // Adds margin around FitText inner div
  & > div {
    margin: 2vw 0;
  }
}
